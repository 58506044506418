import { addLocale } from 'ttag';
import { addLocale as addPrimereactLocale } from 'primereact/api';
import primereactLangs from './i18n/primereact';

const importMomentLocale = (locale)=>import(`moment/locale/${locale}`)

export const languages = ['en','ru']


languages.forEach(l=>{
  if (l!=='en'){
    const translationsObj = require(`./i18n/${l}.po.json`);
    addLocale(l, translationsObj);
    addPrimereactLocale(l, primereactLangs[l])
    importMomentLocale(l)
  }
})
