const ru = {
    startsWith:"Начинается с",
    contains:"Содержит",
    notContains:"Не содержит",
    endsWith:"Заканчивается на",
    equals:"Равно",
    notEquals:"Не равно",
    noFilter:"Нет фильтра",
    filter:"Фильтр",
    lt:"Меньше чем",
    lte:"Меньше или равно",
    gt:"Больше чем",
    gte:"Больше или равно",
    dateIs:"Дата равна",
    dateIsNot:"Дата не равна",
    dateBefore:"Дата до",
    dateAfter:"Дата после",
    custom:"Собственный",
    clear:"Очистить",
    apply:"Применить",
    matchAll:"Каждое из правил",
    matchAny:"Любое из правил",
    addRule:"Добавить правило",
    removeRule:"Удалить правило",
    accept:"Да",
    reject:"Нет",
    choose:"Выбрать",
    upload:"Загрузить",
    cancel:"Отмена",
    close:"Закрыть",
    dayNames:['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    dayNamesShort:['Вск', 'Пон', 'Втр', 'Срд', 'Чтв', 'Птн', 'Сбт'],
    dayNamesMin:['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'сб'],
    monthNames:['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort:['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    today:"Сегодня",
    weekHeader:"Нед",
    firstDayOfWeek:"1",
    dateFormat:"dd.mm.yy",
    weak:"Слабый",
    medium:"Средний",
    strong:"Сильный",
    passwordPrompt:"Введите пароль",
    emptyFilterMessage:"Нет опций",
    emptyMessage:"Результаты не найдены",
    "aria.trueLabel":"Истина",
    "aria.falseLabel":"Ложь",
    "aria.nullLabel":"Не выбрано",
    "aria.pageLabel":"Страница",
    "aria.firstPageLabel":"Первая страница",
    "aria.lastPageLabel":"Последняя страница",
    "aria.nextPageLabel":"Следующая страница",
    "aria.previousPageLabel":"Предыдущая страница",
    "aria.selectLabel":"Выбрать",
    "aria.unselectLabel":"Убрать выделение",
    "aria.expandLabel":"Расширить",
    "aria.collapseLabel":"Свернуть"
}

const primereactLangs = {ru}

export default primereactLangs