const en = {calendar:{
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tommorow]',
    sameYear: 'MMMM D',
    sameElse: 'MMM D, YYYY'
}}

const ru = {calendar:{
    lastDay: '[Вчера]',
    sameDay: '[Сегодня]',
    nextDay: '[Завтра]',
    sameYear: 'D MMMM',
    sameElse: 'D MMM YYYY'
}}

const momentLangs = {en, ru}

export default momentLangs