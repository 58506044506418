import React, { useContext, useState } from "react";
import ProjectContext from "./ProjectContext";
import { t } from 'ttag'
import { ModelContextProvider } from "./_common";

const PipelineContext = React.createContext()
export default PipelineContext
export const PipelineProvider = ({children}) => {
    const {getProjectData} = useContext(ProjectContext)
    const [fullScreen, setFullScreen] = useState()
    const [isHorizontal, setIsHorizontal]=useState()
    const [openedStage, setOpenedStage]=useState(null)
    const [openedUnit, setOpenedUnit]=useState(null)
    const [selectedStages, setSelectedStages]=useState([])
    const [openedTask, setOpenedTask] = useState(null)
    const context = {
        fullScreen,
        setFullScreen,
        isHorizontal,
        setIsHorizontal,
        openedStage,
        setOpenedStage,
        selectedStages,
        setSelectedStages,
        openedUnit,
        setOpenedUnit,
        openedTask,
        setOpenedTask
    }

    return <ModelContextProvider
        fromParams
        model="pipeline"
        loadFromPatch
        Context={PipelineContext}
        loadingMessage={t`Loading pipeline data...`}
        onDataLoad={data=>setIsHorizontal(data.isHorizontal)}
        onDataChange={getProjectData}
        onDelete={getProjectData}
        contextData={context}
        modelLabel={t`Pipeline`}
        saveMessage={t`Pipeline saved`}
    >
        {children}
    </ModelContextProvider>
}
