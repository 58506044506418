import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import React, { Suspense, lazy, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/brands.css'
import './icons/fonts/pipelines-icons.css'
import { FrameLoading } from './components/Layouts';
import moment from 'moment';
import settings from './settings';

// // const lazyDelay = (f) => lazy(() => {
// //     return new Promise(r => {
// //       setTimeout(() => r(f()), 1000);
// //     });
// //   });

const LayoutProvider = lazy(()=>import('./context/providers/LayoutProvider'))
const AuthProvider = lazy(()=>import('./context/providers/AuthProvider'))
const WorkspaceProvider = lazy(()=>import('./context/providers/WorkspaceProvider'))
const UnitProvider = lazy(()=>import('./context/providers/UnitProvider'))
const PipelineProvider = lazy(()=>import('./context/providers/PipelineProvider'))
const ProjectProvider = lazy(()=>import('./context/providers/ProjectProvider'))

const Header = lazy(()=>import("./components/Header"))
const SideMenu = lazy(()=>import("./components/SideMenu"))
const LoginRequired = lazy(()=>import("./components/LoginRequired"))

const LoginCommon = lazy(()=>import('./pages/login/LoginCommon'))
const Oauth = lazy(()=>import('./pages/login/Oauth'))
const LoginScreen = lazy(()=>import('./pages/login/LoginScreen'))
const Logout = lazy(()=>import('./pages/login/Logout'))
const SignUpScreen = lazy(()=>import('./pages/login/SignUpScreen'))
const ResetScreen = lazy(()=>import('./pages/login/ResetScreen'))
const EmailChange = lazy(()=>import('./pages/login/EmailChange'))

const Profile = lazy(()=>import('./pages/profile/Profile'))
const Quickstart = lazy(()=>import('./pages/profile/Quickstart'))

const MainPage = lazy(()=>import('./pages/MainPage'))
const Documentation = lazy(()=>import('./pages/Documentation'))
const Versions = lazy(()=>import('./pages/Versions'))
const Dashboard = lazy(()=>import('./pages/Dashboard'))
const Notifications = lazy(()=>import('./pages/Notifications'))
const TaskPage = lazy(()=>import('./pages/TaskPage'))

const CreateWorkspace = lazy(()=>import('./pages/workspaces/CreateWorkspace'))
const EditWorkspace = lazy(()=>import('./pages/workspaces/EditWorkspace'))
const WorkspacesList = lazy(()=>import('./pages/workspaces/WorkspacesList'))
const InviteLink = lazy(()=>import('./pages/workspaces/InviteLink'))
const SetWorkspace = lazy(()=>import('./pages/workspaces/SetWorkspace'))

const CreateProject = lazy(()=>import('./pages/projects/CreateProject'))
const ProjectView = lazy(()=>import('./pages/projects/ProjectView'))
const ProjectEdit = lazy(()=>import('./pages/projects/ProjectEdit'))

const Pipelines = lazy(()=>import('./pages/pipelines/PipelinesList'))
const PipelineView = lazy(()=>import('./pages/pipelines/PipelineView'))
const EditPipelineFlow = lazy(()=>import('./pages/pipelines/EditPipelineFlow'))
const EditPipeline = lazy(()=>import('./pages/pipelines/EditPipeline'))
const CreatePipeline = lazy(()=>import('./pages/pipelines/CreatePipeline'))

const UnitsList = lazy(()=>import('./pages/units/UnitsList'))
const CreateUnit = lazy(()=>import('./pages/units/CreateUnit'))
const CreateMultipleUnits = lazy(()=>import('./pages/units/CreateMultiple'))
const UnitView = lazy(()=>import('./pages/units/UnitView'))
const EditUnit = lazy(()=>import('./pages/units/EditUnit'))

const App = () => {
    useEffect(()=>{
            if(!settings.maintenance){
            // Local Storage cleanup
            for (var key in localStorage){
                if(key.startsWith('pipelineState_')){
                    const state = JSON.parse(localStorage.getItem(key))
                    const saved_time = moment(state.saved)
                    const diff = moment().diff(saved_time,'days')
                    if(diff>15){
                        localStorage.removeItem(key)
                    }
                }
            }
            if(settings.host && window.location.origin !== settings.host){
                window.location.replace(settings.host+window.location.pathname)
            }
        }
    },[])

    if(settings.maintenance){
        return (
            <div className="w-full min-h-full sm:py-4 flex align-items-stretch sm:align-items-center p-component">
                <div className='centered-card mx-auto'>
                    <div className='min-h-full lg:min-h-0 surface-card lg:border-round shadow-2 min-w-min'>
                        <div className='p-2 lg:p-4 w-full'>
                            
                            <div className='text-center p-5 m-5'>
                                <i className="fa-solid fa-person-digging text-8xl text-primary"></i>
                                <p className='text-4xl text-primary'>Sorry, we are under maintenance. Please try again later.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    return (
        <Router>
            <LayoutProvider>
                <Suspense fallback={<FrameLoading/>}>
                <AuthProvider>
                    <Routes>
                        <Route exact path='/oauth' element={<Oauth/>}/>
                        <Route exact path='logout' element={<Logout/>}/>
                        {/* <Route exact path='test' element={<Test/>}/> */}
                        <Route path='/' element={<Header><Suspense fallback={<FrameLoading/>}><Outlet/></Suspense></Header>}>
                            <Route index element={<MainPage/>}/>
                            <Route path='docs' element={<Documentation/>}/>
                            <Route path='versions' element={<Versions/>}/>
                            <Route path='i/:token' element={<InviteLink/>}/>
                            <Route path='p/:token' element={<InviteLink userLink/>}/>
                            <Route path='change_email' element={<EmailChange/>}/>
                            <Route element={<LoginCommon><Outlet/></LoginCommon>}>
                                <Route path='login' element={<LoginScreen/>}/>
                                <Route path='register' element={<SignUpScreen/>}/>
                                <Route path='reset_pass' element={<ResetScreen/>}/>
                            </Route>
                            <Route path="app" element={<LoginRequired><Outlet/></LoginRequired>}>
                                <Route path='workspaces'>
                                    <Route index element={<WorkspacesList/>}/>
                                    <Route path = 'create' element={<CreateWorkspace/>}/>
                                    <Route path = ':workspaceId' element={<SetWorkspace/>}/>
                                    <Route path = ':workspaceId/settings' element={<SetWorkspace settings/>}/>
                                </Route>
                                <Route path='profile' element={<Profile/>}/>
                                <Route path = 'quickstart' element={<Quickstart/>}/>
                                <Route element={<WorkspaceProvider><Outlet/></WorkspaceProvider>}>
                                    <Route path='notifications' element={<Notifications/>}/>
                                    <Route element={<SideMenu><Suspense fallback={<FrameLoading/>}><Outlet/></Suspense></SideMenu>}>
                                        <Route index element={<Dashboard/>} />
                                        <Route path='tasks/:taskId' element={<TaskPage/>} />
                                        <Route path='workspaces'>
                                            <Route path = 'current' element={<Navigate to='/app'/>}/>
                                            <Route path = 'current/settings' element={<EditWorkspace/>}/>
                                        </Route>
                                        <Route path='projects/create' element={<CreateProject/>}/>
                                        <Route path="projects/:projectId" element={<ProjectProvider><Outlet/></ProjectProvider>}>
                                            <Route index element={<ProjectView/>}/>
                                            <Route path='edit' element={<ProjectEdit/>}/>
                                            <Route path='pipelines'>
                                                <Route index element={<Pipelines/>}/>
                                                <Route path='create' element={<CreatePipeline/>}/>
                                                <Route element={<PipelineProvider><Outlet/></PipelineProvider>}>
                                                    <Route path=':pipelineId' element={<PipelineView/>}/>
                                                    <Route path=':pipelineId/edit' element={<EditPipeline/>}/>
                                                    <Route path=':pipelineId/edit_flow' element={<EditPipelineFlow/>}/>
                                                </Route>
                                            </Route>
                                            <Route path='units'>
                                                <Route index element={<UnitsList/>}/>
                                                <Route path='create' element={<CreateUnit/>}/>
                                                <Route path='create_multiple' element={<CreateMultipleUnits/>}/>
                                                <Route element={<UnitProvider><Outlet/></UnitProvider>}>
                                                    <Route path=':unitId' element={<UnitView/>}/>
                                                    <Route path=':unitId/edit' element={<EditUnit/>}/>
                                                </Route>
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Routes>
                </AuthProvider>
            </Suspense>
        </LayoutProvider>
    </Router>
)}

export default App;

// const Test = ()=>{
//     const modelRequest=useModelRequest()
//     const [data, setData]=useState()
//     return <>
    // {/* <button onClick={()=>{
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    //     modelRequest('workspace').then(r=>{console.log(r.data); setData(r.data)}).catch(e=>{alert('error');throw error})
    // }}>Click me</button>
    // <pre>{JSON.stringify(data,'',2)}</pre> */}
//     </>
// }

// const Test = () =>{
//     const v=[0, 50,100,200,300,400,500,600,700,800,900]
//     const l=['ground','section','card','overlay', 'hover']
//     const c=['blue','green','yellow','cyan','pink','indigo','teal','orange','bluegray','purple','red','primary']
//     const s=['primary', 'secondary', 'success', 'info', 'warning', 'help', 'danger']
//     return <div className=' overflow-auto h-full w-full'>
//         <div className='flex flex-wrap'>{
//             v.concat(l).map(i=>(
//                 <div className={classNames('w-5rem h-5rem', `surface-${i}`)}>{`surface-${i}`}</div>
//             ))}
//             <div className='w-full'></div>
//             {c.map(cc=><>{v.map(i=>
//                 <div className={classNames('w-5rem h-5rem', `bg-${cc}-${i}`)}>{`bg-${cc}-${i}`}</div>
//             )}<div className='w-full'></div></>)}
//         </div>
//         <div className='flex gap-3 my-3'>
//             {s.map(x=>(
//                 <Button label={x} className={`p-button-${x}`}/>
//             ))}
//         </div>
//         <div className='flex gap-3 my-3'>
//             {s.map(x=>(
//                 <Button label={x} className={`p-button-${x} p-button-outlined`}/>
//             ))}
//         </div>
        
//         <div className='flex flex-column gap-3 my-3'>
//             <Checkbox/>
//             <Checkbox checked/>
//             <RadioButton/>
//             <RadioButton checked/>
//             <InputSwitch/>
//             <InputSwitch checked/>
//         </div>
//     </div>
// }
