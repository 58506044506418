import React, { useContext } from "react"
import {t} from 'ttag'
import { ModelContextProvider } from "./_common"
import WorkspaceContext from "./WorkspaceContext"

const ProjectContext = React.createContext()
export default ProjectContext
export const ProjectProvider = ({children}) => {  
    const {getWorkspaceData, workspaceId, changeWorkspace} = useContext(WorkspaceContext)

    const onDataLoad = (data) => {
        localStorage.setItem('lastProject', data.id)
        if(data.workspace!==workspaceId){changeWorkspace(data.workspace, window.location.pathname)}
    }

    return <ModelContextProvider
        fromParams
        model="project"
        loadFromPatch
        Context={ProjectContext}
        loadingMessage={t`Loading project data...`}
        onDataChange={getWorkspaceData}
        onDelete={getWorkspaceData}
        modelLabel={t`Project`}
        saveMessage={t`Project saved`}
        onDataLoad={onDataLoad}
    >
      {children}
    </ModelContextProvider>
}
