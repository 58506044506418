import { createContext, useEffect,  useRef, useState } from "react";
import { Toast } from 'primereact/toast';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { t } from 'ttag'
import { Tooltip } from "primereact/tooltip";
import { useLocale as localize } from 'ttag';
import { languages } from "../i18nInit";
import { CenteredCardLayout } from "../components/Layouts";
import PrimeReact, { locale } from 'primereact/api';
import moment from 'moment';
import momentLangs from "../i18n/moment";
import $ from 'jquery'

moment.calendarFormat = function (myMoment, now) {
    var diff = myMoment.diff(now, 'days', true);
    return (diff>=-1 && diff < 0 ? 'lastDay' :
            diff>=0 && diff < 1 ? 'sameDay' :
            diff>=1 && diff < 2 ? 'nextDay' :
            myMoment.year() === now.year() ? 'sameYear' :
            'sameElse')
};

const LayoutContext = createContext();

export default LayoutContext;

export const LayoutProvider = ({children}) => {
    const [sideBarEnabled, setSideBarEnabled] = useState(localStorage.getItem('sideBarEnabled')?localStorage.getItem('sideBarEnabled')==='true':'false')
    const [sideBarVisible, setSideBarVisible] = useState(false)
    const [smallDevice, setSmallDevice]=useState()
    const [smallDeviceHor, setSmallDeviceHor]=useState()
    const [language, setLanguage] = useState(localStorage.getItem('language') || navigator.language || navigator.userLanguage || 'en')
    const [theme, setTheme] = useState(localStorage.getItem('theme')
        ?localStorage.getItem('theme')
        :(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)?'dark':'light')
    const [error, setError]=useState()
    const [initialLoad, setInitialLoad]=useState(true)
    const [reload, setReload]=useState(false)

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        setTheme(event.matches ? "dark" : "light");
    });

    const toggleTheme=()=>setTheme(t=>t==='dark'?'light':'dark')

    useEffect(()=>{
        var l=language
        if(languages.includes(language)){
            localStorage.setItem('language', language)
            localize(language)
            locale(language)
            moment.locale(language)
            moment.updateLocale(language, momentLangs[language]);
        }
        else if (languages.includes(language.split('-')[0])){
            l=language.split('-')[0]
            setLanguage(l)
        }
        else{
            l='en'
            setLanguage(l)
        }
        localStorage.setItem('language',l)
        if(!initialLoad){setReload(true)}
    },[language,initialLoad])

    useEffect(()=>{
        PrimeReact.changeTheme(theme==='dark'?'light':'dark', theme, 'theme-link')
        localStorage.setItem('theme',theme)
    },[theme])


    const toast = useRef()
    const message = {
        show: (data)=>toast.current?.show(data),
        success: (summary=t`Succes`,detail)=>toast.current?.show({summary,detail,severity:'success'}),
        info: (summary=t`Info`,detail)=>toast.current?.show({summary,detail,severity:'info'}),
        warn: (summary=t`Warning`,detail)=>toast.current?.show({summary,detail,severity:'warn'}),
        error: (summary=t`Error`,detail)=>toast.current?.show({summary,detail,severity:'error'}),
    }

    const onResize=()=>{
        if($(window).width()<990){
            setSmallDevice(true)
            if($(window).width()>576){
                setSmallDeviceHor(true)
            }
            else {
                setSmallDeviceHor(false)
            }
        }
        else {
            setSmallDevice(false)
            setSmallDeviceHor(false)
        }
    }

    useEffect(()=>{
        onResize()
        $(window).on('resize',onResize)
    },[])

    useEffect(()=>setReload(false),[reload])

    useEffect(()=>{
        localStorage.setItem('sideBarEnabled', sideBarEnabled)
    },[sideBarEnabled])

    useEffect(()=>setInitialLoad(false),[])

    const contextData = {
        message,
        language,
        setLanguage,
        setError,
        theme,
        setTheme,
        toggleTheme,
        sideBarEnabled,
        setSideBarEnabled,
        sideBarVisible,
        setSideBarVisible,
        smallDevice,
        smallDeviceHor
    }
    return (
        error
        ? <CenteredCardLayout breadcrumbs={false}>{error}</CenteredCardLayout>
        : <LayoutContext.Provider value={contextData}>
            {!reload&&children}
            <Toast ref={toast}  position="bottom-right" />
            <ConfirmPopup />
            <ConfirmDialog />
            <Tooltip target=".enable-tooltip" mouseTrack mouseTrackLeft={10} position="bottom"/>
        </LayoutContext.Provider>
    );
}
