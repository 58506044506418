import { ProgressSpinner } from 'primereact/progressspinner';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useContext } from 'react';
import ProjectContext from '../context/ProjectContext';
import PipelineContext from '../context/PipelineContext';
import UnitContext from '../context/UnitContext';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import LayoutContext from '../context/LayoutContext';

export const CenteredCardLayout = ({topElement, bottomElement, size='lg', loading=false, breadcrumbs=true, header, children, className, ...props}) => {
    const layoutContext=useContext(LayoutContext)
    const colClasses =
        size==='sm'
        ? "sm:w-10 md:w-6 lg:w-6 xl:w-4"
        : size==='xl'
        ? "md:w-12 lg:w-10 xl:w-8"
        : "md:w-10 lg:w-8 xl:w-5"


    return (
        !layoutContext?.smallDevice?<>
            {breadcrumbs&&<Breadcrumbs/>}
            <div className="w-full flex p-component">
                <div className={classNames('centered-card m-auto', colClasses)}>
                    {header}
                    <div className={classNames('surface-card lg:border-round lg:shadow-2 min-w-min lg:m-5', className)} {...props}>
                        <div className='p-2 lg:p-4 w-full'>
                            {loading
                                ?<div className='w-full h-25rem flex align-items-center'>
                                    <div className='mx-auto'><ProgressSpinner strokeWidth="4"/></div>
                                </div>
                                :children
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
        :<PageLayout className={className} {...props}>{children}</PageLayout>
    )
}

export const FullScreenLoading = ({show=true, children, label=""}) => {
    if(show){
        return <div className={`w-screen h-screen absolute z-5 top-0 left-0 flex flex-column justify-content-center align-items-center surface-100`}>
            <div>
                <ProgressSpinner strokeWidth="4"/>
                {children&&<div className='hidden'>{children}</div>}
            </div>
            <div className='p-component text-500'>{label}</div>
        </div>
    }
}

export const FrameLoading = ({show=true, children, label=""}) => {
    if(show){
        return <div className="w-full h-full absolute top-0 left-0 flex flex-column justify-content-center align-items-center surface-100">
            <div>
                <ProgressSpinner strokeWidth="4"/>
                {children&&<div className='hidden'>{children}</div>}
            </div>
            <div className='p-component text-500'>{label}</div>
        </div>
    }
}

export const LabeledLoading = ({show=true, children, label=""}) => {
    if(show){
        return <div className="p-5 flex flex-column justify-content-center align-items-center">
            <div>
                <ProgressSpinner strokeWidth="4"/>
                {children&&<div className='hidden'>{children}</div>}
            </div>
            <div className='p-component text-500'>{label}</div>
        </div>
    }
}

export const CenteredLoading = ({show=true, className='', children, ...props}) => {
    if(show){
        return(
            <div className={`flex align-items-center justify-content-center h-full w-full p-5 ${className}`} {...props}>
                <ProgressSpinner strokeWidth="4" className="mx-auto"/>
                {children&&<div className='hidden'>{children}</div>}
            </div>
        )
    }
}

export const AsideLoading = ()=>{
    return <div className="absolute z-5 bottom-0 right-0 m-5 animation-delay-1000 fadein animation-duration-1000 animation-fill-backwards">
            <ProgressSpinner strokeWidth="8" className='w-4rem h-4rem'/>
    </div>
}

export const PageLayout = ({loading, children, className, ...props}) => {
    return(
        <>
            
            <div className='w-full lg:p-5 lg:flex lg:align-items-start min-h-full lg:h-auto'>
                <div className={classNames('lg:border-round shadow-1 p-2 md:p-5 surface-card w-full', className)} {...props}>
                    <Breadcrumbs/>
                    {loading
                        ?<FrameLoading/>
                        : children
                    }
                </div>
            </div>
        </>
    )
}

const Breadcrumbs = () => {
    const projectContext = useContext(ProjectContext)
    const pipelineContext = useContext(PipelineContext)
    const unitContext = useContext(UnitContext)
    const nav=useNavigate()

    const home = { icon: 'fas fa-home', command: ()=>nav('/app') }
    const items = []
    if(projectContext){
        items.push({
            label:projectContext.projectData.name,
            command:()=>nav(`/app/projects/${projectContext.projectData.id}`),
        })
    }
    else {return ''}
    if(pipelineContext){
        items.push({
            label:pipelineContext.pipelineData.name,
            command:()=>nav(`/app/projects/${projectContext.projectData.id}/pipelines/${pipelineContext.pipelineData.id}`),
        })
    }
    if(unitContext){
        items.push({
            label:unitContext.unitData.name,
            command:()=>nav(`/app/projects/${projectContext.projectData.id}/units/${unitContext.unitData.id}`),
        })
    }
    if(window.location.pathname.endsWith('/edit')){
        items.push({
            label:'Edit'
        })
    }
    
    return <BreadCrumb model={items} home={home} className='border-none'/>
}
