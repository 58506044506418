import React, { useContext } from "react";
import ProjectContext from "./ProjectContext";
import { t } from 'ttag'
import { ModelContextProvider } from "./_common";

const UnitContext = React.createContext()
export default UnitContext
export const UnitProvider = ({children}) => {
    const {getProjectData} = useContext(ProjectContext)

    return <ModelContextProvider
        fromParams
        model="unit"
        loadFromPatch
        Context={UnitContext}
        loadingMessage={t`Loading unit data...`}
        onDataChange={getProjectData}
        onDelete={getProjectData}
        modelLabel={t`Unit`}
        saveMessage={t`Unit saved`}
    >
        {children}
    </ModelContextProvider>
}
