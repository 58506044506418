import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
// import { useModelRequest } from "../utils/APIMethods"
import { CenteredCardLayout, FrameLoading } from "../components/Layouts"
import {t} from 'ttag'
import { useNavigate, useParams } from "react-router-dom"
import LayoutContext from "./LayoutContext"
import { useModelRequest } from "../utils/APIMethods"
import settings from "../settings"

export const ModelContextProvider = React.forwardRef(({
    model,
    id,
    children,
    loadingMessage,
    loadFromPatch=false,
    getterAction,
    Context,
    contextData,
    ignoreEmpty=false,
    onDataChange,
    onDataLoad,
    onDataPatched,
    home,
    onDelete=()=>{},
    fromParams=false,
    idParam=null,
    saveMessage,
    modelLabel,
    silent=false,
    modelRequest
}, ref) => {

    const params = useParams()
    const param = idParam||`${model}Id`
    id = fromParams?parseInt(params[param]):id
    modelLabel = modelLabel||model
    const [modelId, setModelId] = useState(id)
    const [modelData, setModelData] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [initial, setInitial] = useState(true)
    const _modelRequest = useModelRequest()
    modelRequest = modelRequest || _modelRequest
    const nav = useNavigate()
    const {message}=useContext(LayoutContext)

    const getModelData = useCallback(async() => {
        const wasInitial = initial
        setInitial(false)
        return modelId
        ?modelRequest(model, 'GET', null, modelId, getterAction)
        .then(r=>{
            // console.log(`successfuly get model: "${model} [${modelId}]"`, r.data)
            if(JSON.stringify(r.data)!==JSON.stringify(modelData)){
                if(wasInitial){onDataLoad&&onDataLoad(r.data)}
                else{onDataChange&&onDataChange(r.data)}
            }
            setModelData(r.data)
            setInitial(false)
            setLoading(false)
            return (r.data)
        })
        .catch(error=>{
            message.error(t`Error wile loading data: ${modelLabel} ${modelId}`)
            setLoading(false)
            nav(home)
            throw error
        })
        :null
}, [modelRequest, model, modelId, initial, home, message, modelData, modelLabel, nav, onDataChange, onDataLoad, getterAction])
    
    const patchModelData = useCallback(async(data) => (
        modelId
        ?modelRequest(model, 'PATCH', data, modelId)
        .then(r=>{
            onDataPatched&&onDataPatched()
            !silent&&message.success(t`Saved`, saveMessage||t`${modelLabel} saved`)
            if(loadFromPatch){
                setModelData(r.data)
                setInitial(false)
                if(JSON.stringify(r.data)!==JSON.stringify(modelData)){onDataChange&&onDataChange(r.data, initial)}
                return r.data
            }
            else{
                return getModelData()
            }
        })
        .catch(error=>{
            message.error(t`Error wile sending data: ${modelLabel} ${modelId}`)
            setLoading(false)
            nav(home)
            throw error
        })
        :null
    ), [modelRequest, model, modelId, loadFromPatch, getModelData, initial, home, message, modelData, modelLabel, nav, onDataChange, onDataPatched, saveMessage, silent])

    const deleteModel = useCallback(async()=>(
        modelId
        ?modelRequest(model, 'DELETE', null, modelId)
        .then(()=>{
            onDelete()
            nav(home)
            setModelData()
            setModelId()
        })
        .catch(error=>{
            setError(t`Error wile deleting: ${modelLabel} ${modelId}`)
            throw error
        })
        :null
    ),[modelRequest, model, modelId, nav, home, modelLabel, onDelete])

    useEffect(()=>{
        const newId = fromParams?parseInt(params[param]):id
        if(!newId){
            setLoading(false)
            if(!ignoreEmpty){
                setError(t`Error loading context: no id provided`)
                // console.error(`Error loading ${model} context: no id provided`)
                // home&&nav(home)
            }
        }
        if(parseInt(modelId)!==parseInt(newId)){
            setModelId(newId)
        }
    },[id, ignoreEmpty, fromParams, params, getModelData, home, nav, param, modelId])

    useEffect(()=>{
        // console.log(`${model} id change`,{modelId, id, params, fromParams, ignoreEmpty})
        setError(null)
        if(!modelId){setModelData()}
        else{setInitial(true)}
    },[modelId])

    useEffect(()=>{
        settings.debug&&console.log(`${model} context initialising`)
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(initial){
            // console.log('initial load')
            setLoading(true)
            getModelData()
        }
    },[initial, getModelData])


    const context = useMemo(()=>{
        const capitalizedModelName = model.charAt(0).toUpperCase() + model.slice(1);
        let context = {...contextData, loading, setLoading, setError}
        context[`${model}Data`] = modelData
        context[`${model}Id`] = modelId
        context[`get${capitalizedModelName}Data`] = getModelData
        context[`patch${capitalizedModelName}Data`] = patchModelData
        context[`set${capitalizedModelName}Data`] = setModelData
        context[`set${capitalizedModelName}Id`] = setModelId
        context[`delete${capitalizedModelName}`] = deleteModel
        return context
    }, [model, contextData, loading, setLoading, modelData, getModelData, patchModelData, setModelData, setError, deleteModel, modelId])

    useEffect(()=>{
        if(ref){ref.current = context}
    }, [context, ref])

    if(error){return <CenteredCardLayout>{error}</CenteredCardLayout>}

    return <Context.Provider value={context}>
    {!modelId&&ignoreEmpty
        ? children
        : loading
            ? <FrameLoading label={loadingMessage || t`Loading ${modelLabel}...`}/>
            : error
                ? <CenteredCardLayout>{error}</CenteredCardLayout>
                // : ignoreEmpty&&!modelData
                //     ? <FrameLoading label={loadingMessage || t`Loading ${model}...`}>{children}</FrameLoading>
                //     : children
                : children
    }

    </Context.Provider>
})