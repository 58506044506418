import { createContext, useState, useEffect, useContext, useRef, useCallback, useMemo } from "react";
import AuthContext from "./AuthContext";
import { useModelRequest } from "../utils/APIMethods"
import { useNavigate } from "react-router-dom";
import {t} from 'ttag'
import { ModelContextProvider } from "./_common";

const WorkspaceContext = createContext();
export default WorkspaceContext;

export const WorkspaceProvider = ({children}) => {
    const {userData, patchUserData} = useContext(AuthContext)
    const nav = useNavigate()
    const modelRequest=useModelRequest()
    const [redirect, setRedirect] = useState(false)
    const contextRef = useRef()

    const initId = useMemo(()=>parseInt(localStorage.getItem('current_workspace')) || userData?.recent_workspace,[userData])

    const changeWorkspace = (newId, goto='/app') => {
        if(contextRef.current.workspaceId!==newId){
            contextRef.current.setLoading(true)
            patchUserData({recent_workspace: newId})
            .then(data=>{
                contextRef.current.setWorkspaceId(newId)
                if(newId) {
                    localStorage.setItem('current_workspace', newId)
                    setRedirect(goto)
                }
                else {
                    localStorage.removeItem('current_workspace')
                    nav('/app/workspaces')
                }
            })
        }
        else{nav(goto)}
    }

    const onDataLoad = useCallback(()=>{
        if(redirect){nav(redirect)}
        setRedirect(null)
    },[redirect, nav])
    
    useEffect(()=>{
        if(contextRef.current&&userData&&userData.recent_workspace!==contextRef.current.workspaceId){
            modelRequest('user', 'PATCH', {recent_workspace: contextRef.current.workspaceId}, userData.id)
        }
        if(userData&&contextRef.current?.workspaceId&&contextRef.current.workspaceId!==parseInt(localStorage.getItem('current_workspace'))){
            localStorage.setItem('current_workspace', contextRef.current.workspaceId)
        }
    }, [userData, contextRef.current?.workspaceId, modelRequest])
    
    const contextData = {changeWorkspace}
    if(!userData || userData.workspaces.length<1 || !initId){
        return children
    }
    return initId&&<ModelContextProvider
                id={initId}
                model="workspace"
                loadFromPatch
                Context={WorkspaceContext}
                contextData={contextData}
                ref={contextRef}
                loadingMessage={t`Loading workspace data...`}
                onDataLoad={onDataLoad}
                home='/app/workspaces'
                modelLabel = {t`Workspace`}
                saveMessage={t`Workspace saved`}
            >
                {children}
          </ModelContextProvider>
}
